<template>
  <div>
    <main-nav :activeIndex="activeIndex"></main-nav>
    <div class="solution-header-container" :style="'background: url('+bannerBg+')'">
      <div class="solution-header-text">
        <h1 data-caption-delay="0" class="wow fadeInLeft">Qt</h1>
        <p data-caption-delay="100" class="wow fadeInRight">为开发者构建高性能的软件应用和嵌入式设备提供全方位工具</p>
        <div class="solution-text-button wow fadeInUp" @click="linkUrl(chatUrl)">
          <div>立即咨询</div>
        </div>
      </div>
    </div>
    <div class="product-qt-background wow fadeInLeft">
      <div class="product-qt container">
        <el-row>
          <el-col :xs="24" :sm="24" :md="24" :lg="9" :xl="9" class="product-qt-details">
            <div class="headquarters">产品介绍</div>
            <p class="first-details indent" style="margin-top: 50px">
              Qt是一个基于C++领先的跨平台软件开发框架，旨在为开发者构建高性能的软件应用和嵌入式设备提供一整套从设计、开发、测试到部署的全方位工具。
            </p>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="15" :xl="15">
            <div class="qt-product-picture">
              <img src="../../assets/images/qt-01.png" :style="{'margin-top': isMobile ? '0' : '30px'}" alt="">
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="solution-intros vehicle-solution-sections wow fadeInRight" style="background: #fff">
      <div class="container">
        <div class="normal-title">在智能座舱领域</div>
        <div class="all-intro">
          <el-row>
            <el-col :span="24">
              <p class="intro-detail">Qt通过Design Studio设计执行超高像素的UI/UX，通过Creator开发后端和应用程序逻辑，通过Quick 3D提供用户界面实时渲染引擎，通过Qt
                for MCUs为客户提供基于微控制器GUI的低成本解决方案。</p>
            </el-col>
          </el-row>
          <div class="tab-container qt-tab-container">
            <el-tabs :tab-position="isMobile ? 'top' : 'left'" :class="isMobile ? 'tab-top' : ''">
              <el-tab-pane label="Qt Design Studio">
                <div class="tab-detail-container">
                  <el-row>
                    <el-col :xs="24" :sm="24" :md="24" :lg="10" :xl="10">
                      <p class="tab-inner-intro">Qt Design Studio是UI设计构成工具，设计师可以将主流的2D/3D设计建模工具设计资产轻易地导入Design
                        Studio，并进行交互设计、动画制作、效果渲染等，使用开发者可以使用的QML代码，可以转换为实际的应用程序。</p>
                    </el-col>
                    <el-col :xs="24" :sm="24" :md="24" :lg="14" :xl="14">
                      <img src="../../assets/images/qt-02.png" alt="">
                    </el-col>
                  </el-row>
                </div>
              </el-tab-pane>
              <el-tab-pane label="Qt Creator">
                <div class="tab-detail-container">
                  <el-row>
                    <el-col :xs="24" :sm="24" :md="24" :lg="10" :xl="10">
                      <p class="tab-inner-intro">Qt
                        Creator是跨平台的集成开发环境，它使用C++、QML、JavaScript、Python和其他语言编写代码，可在Windows、Linux和MacOS等桌面操作系统上运行，并允许开发人员在桌面、移动和嵌入式平台创建应用程序，可使用Qt设备模拟器在与目标设备几乎相同的条件下测试、调试应用程序。</p>
                    </el-col>
                    <el-col :xs="24" :sm="24" :md="24" :lg="14" :xl="14">
                      <img src="../../assets/images/qt-03.png" alt="">
                    </el-col>
                  </el-row>
                </div>
              </el-tab-pane>
              <el-tab-pane label="Qt Quick 3D">
                <div class="tab-detail-container">
                  <el-row>
                    <el-col :xs="24" :sm="24" :md="24" :lg="10" :xl="10">
                      <p class="tab-inner-intro">Qt Quick
                        3D提供了用于创建引人入胜和身临其境的用户界面的实时渲染引擎。支持粒子效果、实例化渲染，多层次细节、实时反射、全局光照、物理引擎集成，并可兼容多个图形后端。</p>
                    </el-col>
                    <el-col :xs="24" :sm="24" :md="24" :lg="14" :xl="14">
                      <img src="../../assets/images/qt-04.png" alt="">
                    </el-col>
                  </el-row>
                </div>
              </el-tab-pane>
              <el-tab-pane label="Qt for MCUs">
                <div class="tab-detail-container">
                  <el-row>
                    <el-col :xs="24" :sm="24" :md="24" :lg="10" :xl="10">
                      <p class="tab-inner-intro">Qt for
                        MCUs是专门用于微控制器GUI开发的框架，运行库RAM/Flash仅占用200kB，能利用MCU的2D引擎实现最大绘图效率。它使用描述性语言开发，提供将基于QML的UI转换为原生C++的工具，支持2D
                        Graphic Engine，支持裸跑，支特多种多样的MCU和RTOS的支持，实现极高的性能，最小的RAM/Flash占用。</p>
                    </el-col>
                    <el-col :xs="24" :sm="24" :md="24" :lg="14" :xl="14">
                      <img src="../../assets/images/qt-05.png" alt="">
                    </el-col>
                  </el-row>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </div>
    </div>
    <div class="product-qt-background wow fadeInLeft">
      <div class="product-qt container">
        <el-row>
          <el-col :span="24">
            <div class="headquarters" style="width: 240px">优势亮点</div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <div class="qt-product-picture">
              <img src="../../assets/images/qt-06.png" :style="{width: isMobile ? '80%' : '708px'}" alt="">
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <bzInfo style="margin-top: 0"/>
    <main-footer></main-footer>
  </div>
</template>

<script>
import mainNav from "@/components/MainNav.vue";
import mainFooter from "@/components/MainFooter.vue";
import "@/style/qt.less"
import "@/style/contactUs.less";
import bzInfo from "@/components/BzInfo.vue";
import bannerBg from '@/assets/images/banner-product.jpg';

export default {
  name: "Qt",
  components: {
    mainFooter,
    mainNav,
    bzInfo
  },
  data() {
    return {
      activeIndex: '3-3',
      bannerBg: bannerBg,
      chatUrl: "https://doc.weixin.qq.com/forms/AOEAXQcgAAkAe4APAaVAD0jHqcmrcFRKf",
      isMobile: false,
    }
  },
  mounted() {
    document.title = "Qt - 产品中心 - 东信创智"
    new this.$wow.WOW().init();
    this.isMobile = document.documentElement.clientWidth < 1200
    window.onresize = () => {
      return (() => {
        this.isMobile = document.documentElement.clientWidth < 1200;
      })();
    }
  },
  methods: {
    linkUrl(url) {
      window.open(url, '_blank') // 在新窗口打开外链接
      // window.location.href =this.indexro;  //在本页面打开外部链接
    },
  }
}
</script>

<style scoped>

</style>
